import { getConfigFor } from './configService'
import { SPLIT_FEATURES } from './constants'

const boysBrigadeConfigFn = () => {
  const { configuration: boysBrigadeConfig } = getConfigFor(
    SPLIT_FEATURES.BOYS_BRIGADE
  )
  return boysBrigadeConfig
}

const getBBConfigState = isBoysBrigade => {
  const boysBrigadeConfig = boysBrigadeConfigFn()
  const bannerText = boysBrigadeConfig?.bannerText
  const isBoysBrigadeEnabled = !!(boysBrigadeConfig?.enabled && isBoysBrigade)
  return { isBoysBrigadeEnabled, bannerText }
}

module.exports = {
  boysBrigadeConfigFn,
  getBBConfigState,
}
